/* eslint-disable */
<template>
    <v-container fluid>
		<v-row v-if="!userToken">
            <v-col>
                <v-alert
                    class="mb-0"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2">
                    <v-row align="center">
                        <v-col
                            cols="12"
                            md="auto"
                            class="grow">
                            Pool+ Browser Miner is in demo mode.  Default website wallet is set.
                        </v-col>
                        <v-col cols="auto">
                            <router-link :to="{name: 'login'}">
                                <v-btn
                                    class="mr-3"
                                    small
                                    color="primary">
                                    Login
                                </v-btn>
                            </router-link>
                            <router-link :to="{name: 'signUp'}">
                                <v-btn
                                    small
                                    color="info">
                                    Get Started
                                </v-btn>
                            </router-link>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card id="qs-miner-settings">
                    <v-card-title>
                        <v-icon class="mr-2">
                            mdi-cog
                        </v-icon>
                        Settings
                        <v-spacer>
                        </v-spacer>
                        <v-icon @click="showSettings = !showSettings">
                            {{ showSettings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                    </v-card-title>
                    <v-expand-transition>
                        <v-card-text
                            v-show="showSettings">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3">
                                    <input-coin>
                                    </input-coin>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3">
                                    <input-pool>
                                    </input-pool>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3">
                                    <input-worker>
                                    </input-worker>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3">
                                    <input-wallet>
                                    </input-wallet>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col id="qs-miner-throttle">
                                    <v-row
                                        dense
                                        align="center">
                                        <v-col cols="auto">
                                            <v-icon
                                                color="warning"
                                                small>
                                                mdi-lightning-bolt
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="auto">
                                            <label>
                                                Miner Boost
                                            </label>
                                        </v-col>
                                        <v-col>
                                            <v-chip
                                                small
                                                class="mx-1"
                                                :class="{
                                                    'primary': minerThrottle < 31,
                                                    'warning': minerThrottle > 30 && minerThrottle < 71,
                                                    'error': minerThrottle > 71
                                                }">
                                                {{ 100-minerThrottle }}%
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                    <v-slider
                                        :readonly="isLoggedIn && isFreeAccount"
                                        v-model="minerThrottle"
                                        step="5"
                                        ticks
                                        :color="isFreeAccount ? 'grey' : 'primary'"
                                        max="100"
                                        min="0">
                                        <template v-slot:prepend>
                                            <p class="caption mb-0">
                                                100%
                                            </p>
                                        </template>
                                        <template v-slot:append>
                                            <p class="caption mb-0">
                                                0%
                                            </p>
                                        </template>
                                    </v-slider>
                                    <v-row
                                        jus
                                        v-if="isFreeAccount && isLoggedIn"
                                        dense
                                        justify="center">
                                        <v-col cols="auto">
                                            <router-link to="/sign-up">
                                                <v-btn
                                                    outlined
                                                    color="warning">
                                                    Upgrade to unlock miner boost
                                                </v-btn>
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row id="qs-miner-statistics">
            <v-col
                cols="12"
                sm="6"
                md="3"
                v-for="(stats, key) in minerStats"
                :key="key">
                <data-card :info="stats">
                </data-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card id="qs-miner-chart">
                    <v-card-title>
						<v-icon class="mr-2">
							mdi-chart-line
						</v-icon>
						Hash Rate
						<v-chip class="ml-5">
							<template v-if="minerActive">
								{{hashRateSeconds}} H/s
							</template>
							<template v-else>
                                {{$getConst('minerIdle')}}
							</template>
						</v-chip>
                        <v-divider
                            class="mx-3"
                            vertical>
                        </v-divider>
                        <p class="mb-0 caption">
                            Worker:
                        </p>
                        <v-chip
                            class="ml-3"
                            pill>
                            <v-avatar
                                x-small
                                left
                                :color="activeWorker.color">
                            </v-avatar>
                            {{activeWorker.name}}
                        </v-chip>
						<v-spacer>
						</v-spacer>
						<v-switch
							hide-details
							class="mr-5 mt-0"
							dense
							v-model="chartOn"
							:label="chartOn ? 'Chart On' : 'Chart Off'">
						</v-switch>
						<v-icon
                            v-if="chartOn"
                            @click="showChart = !showChart">
							{{ showChart ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
						</v-icon>
                    </v-card-title>
                    <v-expand-transition>
                        <v-card-text v-if="showChart && chartOn">
                            <chart>
                            </chart>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row id="qs-miner-controls">
            <template>
                <v-col>
                    <v-btn
                        block
                        x-large
                        :disabled="!minerActive"
                        color="error"
                        @click="stopMiner()">
                        Stop Miner
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        block
                        x-large
                        :disabled="minerActive"
                        color="primary"
                        @click="startMinerMain()">
                        Start Miner
                    </v-btn>
                </v-col>
            </template>
        </v-row>
        <v-row>
            <v-col>
                <logs id="qs-miner-logs">
                </logs>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text class="text-center">
                        <p class="caption mb-0 navbackground--text text--lighten-4">
                            Pool data is received from the pool's api when it's acceptable. Data may be delayed or
                            unavailable depending on the pools API network.
                        </p>
                        <p class="caption mb-0 navbackground--text text--lighten-4">
                            Earnings from mining is sent directly to your wallet address specified once
                            you have reached the pool's minimum payout.
                            <router-link :to="{name: 'faqs'}">
                                Click here
                            </router-link>
                            to learn more.
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <ad-pop-up v-if="isFreeAccount">
        </ad-pop-up>
		<v-tour
			name="miner"
			:steps="qs.miner.steps">
		</v-tour>
    </v-container>
</template>

<script>
    import InputCoin from './../components/miner/InputCoin'
    import InputPool from './../components/miner/InputPool'
    import InputWorker from './../components/miner/InputWorker'
	import InputWallet from "@/components/miner/InputWallet"
    import DataCard from "./../components/miner/DataCard"
    import Chart from './../components/miner/Chart'
    import Logs from './../components/miner/Log'
    import Ad from '../components/ads/GeneralAd'
    import AdPopUp from '../components/ads/PopUp'

    export default {
        name: 'HelloWorld',
        metaInfo: {
            titleTemplate: 'Miner - %s'
        },
        data() {
            return {
                data: {},
                minerStats: {
                    shares: {
                        color: 'info',
                        icon: 'mdi-rocket-launch',
                        title: 'Shares',
                        text: ''
                    },
                    unpaid: {
                        color: 'warning',
                        icon: 'mdi-account-cash',
                        title: 'Confirmed Balance',
                        text: ''
                    },
                    paid: {
                        color: 'primary',
                        icon: 'mdi-bank-transfer-in',
                        title: 'Total Paid',
                        text: ''
                    },
                    minPayout: {
                        color: 'accent',
                        icon: 'mdi-bullseye',
                        title: 'Min Payout',
                        text: ''
                    }
                },
                showSettings: !this.$vuetify.breakpoint.sm,
                showChart: true
            }
        },
        components: {
            InputCoin,
            InputPool,
            InputWorker,
			InputWallet,
            DataCard,
            Chart,
            Logs,
            Ad,
            AdPopUp
        },
        computed: {
            coinUpper() {
                return this.$_.upperCase(this.minerCoin);
            },
			hashRateSeconds() {
				return this.$_.last(this.minerChart.datasets[0].data);
			}
        },
        watch: {
            poolApiData(value) {
                // console.log(value)
                let payout;
                if (this.$_.isEmpty(value)) {
                    payout = '0.3' + this.coinUpper
                } else {
                    payout = (value.config.payout.defaultWallet / value.config.sigDivisor) + ' '
                        + this.coinUpper
                }
                this.$set(this.minerStats.minPayout, 'text', payout)
            },
            walletApiData(value) {
                if ((this.minerCoin === 'msr' || this.minerCoin === 'sumo' || this.minerCoin === 'lthn') && value) {
                    this.$set(this.minerStats.shares, 'text', value.collective.validShares)
                    if (this.$_.size(this.poolApiData) > 0) {
                        let divider = this.poolApiData.config.sigDivisor

                        let totalPaid = value.revenue.totalPaid / divider
                        this.$set(this.minerStats.paid, 'text', this.roundNumber(totalPaid) + ' ' + this.coinUpper)

                        let unPaid = value.revenue.confirmedBalance / divider
                        this.$set(this.minerStats.unpaid, 'text', this.roundNumber(unPaid) + ' ' + this.coinUpper)
                    }
                } else {
                    let divider = 1000000000000
                    this.$set(this.minerStats.shares, 'text', value.validShares ? value.validShares : 0)

                    let totalPaid = value.amtPaid / divider
                    this.$set(this.minerStats.paid, 'text', this.roundNumber(totalPaid) + ' ' + this.coinUpper)

                    let unPaid = value.amtDue / divider
                    this.$set(this.minerStats.unpaid, 'text', this.roundNumber(unPaid) + ' ' + this.coinUpper)
                }
            }
        },
        methods: {
            startMinerMain() {
                this.$EventBus.$emit('showAd')
                this.setDataCardText('Waiting for Data')
                this.$EventBus.$emit('startMiner')
            },
            stopMiner() {
                this.$EventBus.$emit('stopMiner')
            },
            setDataCardText(text) {
                this.$_.forEach(this.minerStats, function(value) {
                    value.text = text
                });
            }
        },
        mounted() {
            document.querySelectorAll("a").forEach(function(a) {
                a.setAttribute('target', '_blank');
            });
        }
    }
</script>

<style
    lang="scss"
    scoped>
    .graph-card {
        height: 500px;
    }
</style>