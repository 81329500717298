<template>
    <div>
        <line-chart
            :height="200"
            :chart-data="chartOn ? dataCollection : {}">
        </line-chart>
        <v-row>
            <v-col class="text-center">
                <p class="mb-0">
                    <a
                        class="warning--text"
                        href="https://monerominer.rocks/affiliate-area/" target="_blank">
                        Want to earn more?
                    </a>
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
	import LineChart from './../../plugins/line-chart'

    /**
     * @group Miner
     * Chart component for the miner.  Uses a 3rd party library to display the chart.
     */
	export default {
		name: 'Chart',
		components: {
			LineChart
		},
		computed: {
            /**
             * @vuese
             * Returns object of hashes.
             */
			dataCollection() {
				return this.minerChart;
			}
		}
	}
</script>