<template>
	<v-row
		align="center"
		dense>
		<v-col>
			<v-select
				dense
				hide-details="auto"
				v-model="walletData"
				:items="items"
				item-text="name"
				item-value="address"
				label="Wallet"
				outlined
				:menu-props="{ offsetY: true }">
				<template slot="no-data">
					<v-alert
						class="my-0 mx-2"
						outlined
						type="warning">
						No wallets, please add one
					</v-alert>
				</template>
			</v-select>
		</v-col>
		<v-col cols="auto">
			<v-btn
				color="primary"
				@click="addWallet()">
				Add
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
    /**
     * @group Miner
     * Select for selecting which wallet to use with the miner.
     */
    export default {
        computed: {
            /**
             * @vuese
             * Get wallets based on what coin is selected with InputCoin component.
             */
            items() {
                let _this = this;
                let items = this.$_.filter(this.userWallets, function(o) { return o.coin === _this.minerCoin })
                return items ? items : []
            }
        },
        watch: {
            /**
             * @vuese
             * Set wallet when minerCoin is updated.
             */
            minerCoin() {
                if (this.$_.size(this.items) > 0) {
                    this.walletData = this.items[0].address
                } else {
                    this.walletData = [];
                }
            }
        },
    }
</script>