<template>
    <div>
        <v-alert
			class="mb-0"
            border="bottom"     
            colored-border
            :icon="info.icon"
            :color="info.color">
            <h3>
                {{info.title}}
            </h3>
            <v-row align="center" dense>
                <v-col cols="auto">
                    <p
                        class="mb-0"
                        :class="{'grey--text' : !minerActive || !isLoggedIn}">
                        {{message}}
                    </p>
                </v-col>
                <v-col cols="auto">
                    <v-slide-x-transition>
                        <v-icon
                            small
                            v-if="isLoggedIn && loading"
                            :color="info.color">
                            mdi-check
                        </v-icon>
                    </v-slide-x-transition>
                </v-col>
            </v-row>
        </v-alert>        
    </div>
</template>

<script>
    /**
     * @group Miner
     * For displaying the responses that the pool sends back.
     */
	export default {
        data() {
            return {
                loading: false
            }
        },
		props: {
            // Consists of title and text data.
			info: {
				type: Object,
				required: true
			}
		},
        computed: {
            message() {
                if (!this.isLoggedIn) {
                    return 'Demo Mode'
                } else {
                    if (this.minerActive) {
                        return this.info.text;
                    } else {
                        return 'Waiting for Start'
                    }
                }
            }
        },
        created() {
            this.$EventBus.$on('dataCardLoading', (status) => this.loading = status)
        }
	}
</script>