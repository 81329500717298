<template>
    <v-row
        align="center"
        dense>
        <v-col>
            <v-select
                dense
                hide-details="auto"
                v-model="minerWorker"
                :items="items"
                item-text="name"
                item-value="id"
                label="Worker"
                outlined
                :menu-props="{ offsetY: true }">
                <template v-slot:selection="{ item }">
                    <v-avatar
                        class="mr-2"
                        size="20"
                        :color="item.color">
                    </v-avatar>
                    {{item.name}}
                </template>
                <template v-slot:item="{ item }">
                    <v-avatar
                        class="mr-2"
                        size="20"
                        :color="item.color">
                    </v-avatar>
                    {{item.name}}
                </template>
                <template slot="no-data">
                    <v-alert
                        class="my-0 mx-2"
                        outlined
                        type="warning">
                        No workers, will default to "x"
                    </v-alert>
                </template>
            </v-select>
        </v-col>
        <v-col cols="auto">
            <v-btn
                color="primary"
                @click="addWorker()">
                Add
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    /**
     * @group Miner
     * Select for selecting which worker to use with the miner.  Workers will only be returned if it is not a free account.
     */
    export default {
        computed: {
            /**
             * @vuese
             * If free account return empty array.
             */
            items() {
                return this.userWorkers && !this.isFreeAccount ? this.userWorkers : []
            }
        }
    }
</script>