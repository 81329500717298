<template>
	<v-select
		dense
		hide-details="auto"
		v-model="minerPool"
		:items="selectedCoinPools"
		item-text="name"
		item-value="url"
		label="Pool"
		outlined
		:menu-props="{ offsetY: true }">
	</v-select>
</template>

<script>
    /**
     * @vuese
     * @group Miner
     * Input to select the coin to mine.
     */
    export default {
        computed: {
            /**
             * @vuese
             * Updates when the InputCoin changes.
             */
            selectedCoinPools() {
                return this.items[this.minerCoin]
            }
        },
        data() {
            return {
                /**
                 * @vuese
                 * Static pool urls for server miner.
                 */
                items: {
                    xmr: [
                        {
                            name: 'MoneroOcean',
                            url: 'moneroocean.stream:100'
                        }
                    ],
                    msr: [
                        {
                            name: 'Masari Hash Vault Pro',
                            url: 'masari.hashvault.pro'
                        }
                    ],
                    sumo: [
                        {
                            name: 'Sumokoin Hash Vault Pro',
                            url: 'sumokoin.hashvault.pro'
                        }
                    ],
                    lthn: [
                        {
                            name: 'Lethean Hash Vault Pro',
                            url: 'lethean.hashvault.pro'
                        }
                    ]
                }
            }
        },
        watch: {
            /**
             * @vuese
             * Set pool when minerCoin is updated.
             */
            minerCoin(value) {
                this.minerPool = this.items[value][0].url
            }
        },
        methods: {
            /**
             * @vuese
             * Set pool address on mount.
             */
            setMinerPool() {
                this.minerPool = this.items[this.minerCoin][0].url
            }
        },
        mounted() {
            this.setMinerPool();
        }
    }
</script>