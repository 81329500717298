<template>
    <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        max-width="900"
        v-model="dialog">
        <v-card>
            <v-card-title class="blue">
                This might interest you...
                <v-spacer>
                </v-spacer>
                <v-icon @click="dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <ad
                    ad-slot="8225125409"
                    format="auto"
                    :flat="true">
                </ad>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn
                            text
                            class="warning"
                            @click="toggleAction()">
                            Stop seeing ads!
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Ad from './GeneralAd'

    export default {
        data() {
            return {
                dialog: this.show
            }
        },
        components: {
            Ad
        },
        methods: {
            /**
             * @vuese
             * Used to direct the user to the sign in page.
             */
            toggleAction() {
                this.dialog = false;
                this.$EventBus.$emit('showUpSellGold');
            }
        },
        mounted() {
            this.$EventBus.$on('showAd', () => setTimeout(() => this.dialog = true, 10000))
        }
    }
</script>