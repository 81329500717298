<template>
    <v-card>
        <v-card-title>
            <v-icon class="mr-2">
                mdi-post
            </v-icon>
            Logs
            <v-spacer>
            </v-spacer>
            <v-switch
                hide-details
                class="mr-5 mt-0"
                dense
                v-model="logsOn"
                :label="logsOn ? 'Logs On' : 'Logs Off'">
            </v-switch>
            <v-icon @click="show = !show">
                {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
        </v-card-title>
        <v-expand-transition>
            <v-card-text v-show="show">
                <v-card
                    ref="logContainer"
                    class="logs"
                    height="400px"
                    outlined>
                    <v-card-text class="py-0">
                        <template v-if="!$_.isEmpty(minerLogs)">
                            <div class="my-2">
                                <v-row
                                    dense
                                    v-for="(item, key) in minerLogs"
                                    :key="key">
                                    <v-col class="py-0">
                                        <p class="caption mb-0">
                                            <span :class="item.color+'--text'">{{item.action}}</span>
                                            : {{item.text}}
                                        </p>
                                    </v-col>
                                    <v-col
                                        cols="auto"
                                        class="py-0">
                                        <p class="caption mb-0">
                                            {{item.timestamp}}
                                        </p>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <v-row
                            class="my-2"
                            v-else>
                            <v-col class="text-center">
                                <p class="mb-0 caption">
                                    There are no miner logs :(
                                    <br/>
                                    Start mining to see this cool feature
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                show: true
            }
        },
        watch: {
            minerLogs() {
                this.$refs.logContainer.$el.scrollTop = this.$refs.logContainer.$el.scrollHeight
            }
        }
    }
</script>

<style lang="scss" scopped>
    .logs {
        overflow-y: scroll;
    }
</style>