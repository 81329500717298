<template>
    <v-select
        dense
        hide-details="auto"
        v-model="minerCoin"
        :items="coins"
        item-text="name"
        item-value="slug"
        label="Coin"
        outlined
        :menu-props="{ offsetY: true }">
        <template v-slot:selection="{ item }">
            <v-avatar
                class="mr-2"
                size="20">
                <img
                    :src="getLogo(item.slug)"
                    :alt="item.slug">
            </v-avatar>
            {{item.name}}
        </template>
        <template v-slot:item="{ item }">
            <v-avatar
                class="mr-2"
                size="20">
                <img
                    :src="getLogo(item.slug)"
                    :alt="item.slug">
            </v-avatar>
            {{item.name}}
        </template>
    </v-select>
</template>

<script>
    /**
     * @vuese
     * @group Miner
     * Input to select the coin to mine.
     */
    export default {
        name: 'InputCoin'
    }
</script>